export const AUTH_ROUTES = {
  HOME: '/',
  IAM: '/iam',
  LOGIN: '/login',
  REGISTER: '/register',
  FORGOT_PASSWORD: '/forgot-password',
  EMAIL_VERIFY: '/verify-email',
  REGISTRATION_SUCCESS: 'registration-success',
  REGISTER_STEP_1: '/register/step-1',
  REGISTER_STEP_2: '/register/step-2',
  REGISTER_STEP_3: '/register/step-3',
  REGISTER_TEAM: '/register/team',
  REGISTER_COACH: '/register/coach',
  REGISTER_SPORT: '/register/sport',
  REGISTER_AGENT: '/register/agent',
  REGISTER_SCHOOL_SPORT: '/register/school/sport',
  REGISTER_SCHOOL: '/register/school',
};

export const HOME_ROUTES = {
  INDEX: '/home',
  FAVOURITES: '/favourites',
  NOTIFICATIONS: '/notifications',
  CATEGORY: '/category',
  PROFILE: '/profile',
  ADD_PLAYER_MEDIA: '/add-player-media',
  MEMBER: '/member',
  AGENT: '/agent',
  SCHOOL: '/school',
  COACH: '/coach',
  TEAM: '/team',
  PLAYERS: '/players',
  MEMBERS: '/members',
  PLAYER_HIGHLIGHTS: '/player/highlights/:id',
  PROFILE_EDIT: '/profile/edit',
  PROFILE_CHANGE_PASSWORD: '/profile/change-password',
  PROFILE_STEP_1: '/profile/step-1',
  PROFILE_STEP_2: '/profile/step-2',
  PROFILE_STEP_3: '/profile/step-3',
  PROFILE_AGENT: '/profile/agent',
  PROFILE_SCHOOL: '/profile/school',
  MESSAGES: '/messages',
  CHAT: '/chat',
  ABOUT_US: '/about-us',
  CONTACT_US: '/contact-us',
  TERMS_AND_CONDITIONS: '/terms-conditions',
  PRIVACY_AND_POLICY: '/privacy-policy',
  SETTINGS: '/settings',
  ENQUIRY: '/enquiry',
  ADD_PLAYER: '/add-player',
  MESSAGE_REQUESTS: '/messages/requests',
  PLAYER_REGISTER_STEP_1: '/player/register/step-1',
  PLAYER_REGISTER_STEP_2: '/player/register/step-2',
  SCHOOL_PLAYER_REGISTER_STEP_1: '/school/player/register/step-1',
  SCHOOL_PLAYER_REGISTER_STEP_2: '/school/player/register/step-2',
};