import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  child,
  getDatabase,
  limitToLast,
  onValue,
  push,
  query,
  ref,
  set,
  update,
} from "firebase/database";
import Page from "../../../components/Page";
import { app } from "../../../services/Firebase";
import SimpleBackdrop from "../../../components/Common/SimpleBackdrop";
import Navbar from "../../../layouts/Navigation";
import { useParams } from "react-router-dom";
import { fetchRecord, postRecord } from "../../../services/Axios";
import { PROFILE, REPORT, PLAYERS } from "../../../constants/ApiEndPoint";
import CustomPrimaryButton from "../../../components/Common/CustomPrimaryButton";
import AppColor from "../../../utils/color";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { reportUser } from "../../../stores/actions/Authorize";
import Notify from "../../../components/Common/Notify";
import { HOME_ROUTES } from "../../../constants/site-routes";
import axios from 'axios';
import Configs from "../../../configs/Configs";
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "50%",
  },
  chatSection: {
    marginTop: 10,
  },
  dropdownMenu: {
    position: "absolute",
    listStyleType: "none",
    margin: "5px 0",
    padding: 10,
    border: "1px solid #eaf9f9",
    width: 170,
    marginTop: "15px",
    background: "#eaf9f9",
    marginLeft: 25,
    borderRadius: 5,
  },
  dropdownMenuItem: {
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      background: "#efefef",
      borderRadius: 5,
    },
  },
  menuIcon: {
    cursor: "pointer",
    transform: "rotate(90deg)",
    backgroundColor: AppColor.grayBackground,
    padding: 5,
    borderRadius: 5,
  },
  modal: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: 500,
    background: "white",
    boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
    borderRadius: 8,
    padding: "16px",
    height: 380,
  },
  textarea: {
    width: "97%!important",
    marginBottom: "30px",
    fontFamily: "Montserrat, sans-serif",
    backgroundColor: "#e3fbfc",
    borderRadius: 4,
    border: "1px solid #7CD1DC",
    resize: "none",
    height: 80,
    paddingLeft: 15,
    paddingTop: 10,
  },
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Chat() {
  const queryParams = useQuery();
  const playerId = queryParams.get('player');
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.authorize);
  const classes = useStyles();
  const db = getDatabase(app);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [player, setPlayer] = useState(null);
  const [conversation, setConversation] = useState(null);
  const [showOptionBlock, setShowOptionBlock] = useState(false);
  const bottomRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [message, setMessage] = useState("");
  const [reasonId, setReasonId] = useState("");
  const [showConversation, setShowConversation] = useState(false);
  const [endedConversation, setEndedConversation] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const chatRef = useRef(null);
  const [limit, setLimit] = useState(20);
  const dispatch = useDispatch();
  const [contactPlayer, setContactPlayer] = useState(null);

  const fetchPlayers = async () => {
    setLoading(true);
    const response = await fetchRecord(PROFILE.DETAIL, id);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setPlayer(response.data);
    }
  };

  const fetchReportReasons = async () => {
    const response = await fetchRecord(REPORT.REASONS);
    if (response && response.data && response.status === 200) {
      setReasons(response.data);
    }
  };

  useEffect(() => {
    fetchPlayers();
    fetchReportReasons();
    fetchContactPlayer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchContactPlayer = async () => {
    const response = await fetchRecord(PLAYERS.LIST, playerId);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setContactPlayer(response.data);
    }
  }

  useEffect(() => {
    setLoading(true);
    const conversationQuery = ref(db, `conversations/${user.id}/${player?.id}`);
    onValue(conversationQuery, (snapshot) => {
      setLoading(false);
      const data = snapshot.val();
      if (!data) {
        setShowOptionBlock(true);
      } else {
        setConversation(data);
        if (
          data &&
          data.conversationStatus === 3 &&
          endedConversation === false
        ) {
          setShowConversation(false);
        } else {
          setShowConversation(true);
        }
      }
    });

    let messagePath = `messages/${user.id}_${player?.id}`;
    if (player?.id < user.id) {
      messagePath = `messages/${player?.id}_${user.id}`;
    }
    const q = query(ref(db, messagePath), limitToLast(limit));
    return onValue(q, (snapshot) => {
      setLoading(false);
      const data = snapshot.val();
      if (data) {
        const messageList = Object.values(data);
        setMessages(messageList);
      }
    });
  }, [db, endedConversation, limit, player?.id, user.id]);

  const handleSendMessage = () => {
    let messagePath = `messages/${user.id}_${player?.id}`;
    if (player?.id < user.id) {
      messagePath = `messages/${player?.id}_${user.id}`;
    }

    const message_id = push(child(ref(db), messagePath)).key;

    const data = {
      created_at: Date.now(),
      message: newMessage,
      message_id: message_id,
      message_type: 1,
      receiverStatus: `${player.id}1`,
      receiver_id: player.id,
      receiver_avatar: player.avatar_url ? player.avatar_url : "",
      receiver_name: player.full_name,
      sender_avatar: user.avatar_url ? user.avatar_url : "",
      sender_id: user.id,
      sender_name: user.full_name,
      status: 1,
    };

    const newMessageDetail = {};
    newMessageDetail[`${messagePath}/${message_id}`] = data;

    update(ref(db), newMessageDetail);

    conversation.message_id = message_id;
    conversation.created_at = Date.now();
    conversation.message = newMessage;

    const conversationSenderQuery = ref(
      db,
      `conversations/${user.id}/${player?.id}`
    );
    set(conversationSenderQuery, conversation);

    const conversationReceiverQuery = ref(
      db,
      `conversations/${player?.id}/${user.id}`
    );
    set(conversationReceiverQuery, conversation);

    setNewMessage("");

    const userQuery = ref(db, `users/${player?.id}`);
    onValue(userQuery, (snapshot) => {
      const data = snapshot.val();
      console.log(data, Configs.FCM_KEY)
      if (data && data.fcmToken) {
        axios.post('https://fcm.googleapis.com/fcm/send', {
          "to": data.fcmToken,
          "notification": {
            "title": user.full_name + " has sent you message",
            "body": newMessage,
          },
          "data": {
            "title": user.full_name + " has sent you message",
            "body": newMessage,
          }
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': Configs.FCM_KEY
          }
        });
      }
    });
  };

  const [requestOption, setRequestOption] = useState(null);

  const handleContactRequestOption = (value) => {
    setRequestOption(value);
  };

  const handleNext = async () => {
    if (requestOption) {
      let messageRef = `messages/${user.id}_${player?.id}`;
      if (player?.id < user.id) {
        messageRef = `messages/${player?.id}_${user.id}`;
      }
      const message_id = push(child(ref(db), messageRef)).key;

      const conversationSenderRef = ref(
        db,
        `conversations/${user.id}/${player?.id}`
      );
      set(conversationSenderRef, {
        conversationStatus: 0,
        created_at: Date.now(),
        message: `Hi ${contactPlayer?.name ?? player.full_name}`,
        message_id: message_id,
        message_type: 1,
        requestOption: requestOption,
        receiverStatus: player.id + "1",
        receiver_id: player.id,
        receiver_avatar: player.avatar_url ? player.avatar_url : "",
        receiver_name: player.full_name,
        sender_avatar: user.avatar_url ? user.avatar_url : "",
        sender_id: user.id,
        sender_name: user.full_name,
        status: 4,
      });

      const conversationReceiverRef = ref(
        db,
        `conversations/${player?.id}/${user.id}`
      );
      set(conversationReceiverRef, {
        conversationStatus: 0,
        created_at: Date.now(),
        message: `Hi ${contactPlayer?.name ?? player.full_name}`,
        message_id: message_id,
        message_type: 1,
        requestOption: requestOption,
        receiverStatus: player.id + "1",
        receiver_id: player.id,
        receiver_avatar: player.avatar_url ? player.avatar_url : "",
        receiver_name: player.full_name,
        sender_avatar: user.avatar_url ? user.avatar_url : "",
        sender_id: user.id,
        sender_name: user.full_name,
        status: 4,
      });

      const data = {
        created_at: Date.now(),
        message: `Hi ${contactPlayer?.name ?? player.full_name}`,
        message_id: message_id,
        message_type: 1,
        receiverStatus: `${player.id}1`,
        receiver_id: player.id,
        receiver_avatar: player.avatar_url ? player.avatar_url : "",
        receiver_name: player.full_name,
        sender_avatar: user.avatar_url ? user.avatar_url : "",
        sender_id: user.id,
        sender_name: user.full_name,
        status: 1,
      };

      const newMessageDetail = {};
      newMessageDetail[`${messageRef}/${message_id}`] = data;

      update(ref(db), newMessageDetail);

      await postRecord(HOME_ROUTES.NOTIFICATIONS, {
        from_id: user.id,
        to_id: player.id,
        type: "received_new_message",
      });
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  const handleAccept = () => {
    conversation.conversationStatus = 1;
    const conversationReceiverQuery = ref(
      db,
      `conversations/${user.id}/${player?.id}`
    );
    set(conversationReceiverQuery, conversation);

    const conversationSenderQuery = ref(
      db,
      `conversations/${player?.id}/${user?.id}`
    );
    set(conversationSenderQuery, conversation);
  };

  const handleBlock = () => {
    conversation.conversationStatus = 2;
    conversation.blockedUserId = player?.id;
    const conversationQuery = ref(db, `conversations/${user.id}/${player?.id}`);
    set(conversationQuery, conversation);

    const conversationSenderQuery = ref(
      db,
      `conversations/${player?.id}/${user?.id}`
    );
    set(conversationSenderQuery, conversation);
  };

  const handleUnblock = () => {
    conversation.conversationStatus = 1;
    conversation.blockedUserId = null;
    const conversationQuery = ref(db, `conversations/${user.id}/${player?.id}`);
    set(conversationQuery, conversation);

    const conversationSenderQuery = ref(
      db,
      `conversations/${player?.id}/${user?.id}`
    );
    set(conversationSenderQuery, conversation);
  };

  useEffect(() => {
    if (chatRef.current && chatRef.current.scrollTop === 0 && limit > 20) {
      console.log(chatRef.current.scrollTop);
    } else {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages, limit]);

  const [isOptionsVisible, setOptionsVisible] = useState(false);

  const toggleOptions = () => {
    setOptionsVisible(!isOptionsVisible);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleReport = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await dispatch(
      reportUser({
        reportedUserId: player.id,
        reasonId: reasonId,
        message: message,
        blockStatus: 1,
      })
    );
    setLoading(false);
    if (response && response.status === 200) {
      setMessage("");
      setReasonId("");
      setOpen(false);
      if (isBlocked) {
        handleBlock();
      }
    }
  };

  const handleEndConversation = () => {
    setEndedConversation(true);
    conversation.conversationStatus = 3;
    conversation.blockedUserId = conversation.sender_id;
    const conversationQuery = ref(db, `conversations/${user.id}/${player?.id}`);
    set(conversationQuery, conversation);

    const conversationSenderQuery = ref(
      db,
      `conversations/${player?.id}/${user?.id}`
    );
    set(conversationSenderQuery, conversation);
  };

  const handleScroll = () => {
    const divElement = chatRef.current;
    if (divElement.scrollTop === 0) {
      setLimit(limit + 20);
    }
  };

  const handleCheck = () => {
    setIsBlocked(!isBlocked);
  };

  return (
    <Page className={classes.root} title="Chat" backgroundColor="#ffffff">
      {loading && <SimpleBackdrop />}
      <Navbar />
      <Container className={classes.container}>
        <div className="typography-with-icon">
          <Typography
            variant="h4"
            style={{ fontWeight: "900", marginBottom: 20 }}
          >
            { player?.full_name ?? "N/A"}
          </Typography>
          {conversation && conversation.conversationStatus === 1 && (
            <div className="three-dots-icon" onClick={toggleOptions}>
              <BiDotsVerticalRounded className={classes.menuIcon} />
              {isOptionsVisible && (
                <ul className={classes.dropdownMenu}>
                  <li
                    className={classes.dropdownMenuItem}
                    style={{ marginBottom: 10 }}
                    onClick={() => handleReport()}
                  >
                    <Typography>Report</Typography>
                  </li>
                  <li
                    className={classes.dropdownMenuItem}
                    onClick={() => handleEndConversation()}
                  >
                    <Typography>End Conversation</Typography>
                  </li>
                </ul>
              )}
            </div>
          )}
        </div>
        {showConversation && (
          <Grid container component={Paper} className={classes.chatSection}>
            <Grid item xs={12}>
              <List
                className={classes.messageArea}
                style={{ height: 450, overflowY: "scroll" }}
                ref={chatRef}
                onScroll={handleScroll}
              >
                {messages.map((message, index) => (
                  <ListItem key={index}>
                    {message.sender_id !== user.id ? (
                      <Grid container>
                        <Grid item xs={8}>
                          <Grid container>
                            <Grid item xs={2}>
                              <Avatar
                                alt={message.sender_name}
                                src={message.sender_avatar}
                                style={{
                                  width: 40,
                                  height: 40,
                                  borderRadius: "10%",
                                  objectFit: "cover",
                                }}
                              />
                            </Grid>
                            <Grid item xs={10}>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Typography>
                                    <span
                                      style={{
                                        float:
                                          message.sender_id === user.id
                                            ? "right"
                                            : "left",
                                        backgroundColor:
                                          AppColor.grayBackground,
                                        padding: 10,
                                        borderRadius: 5,
                                      }}
                                    >
                                      {message.message}
                                    </span>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <ListItemText
                                    align={
                                      message.sender_id === user.id
                                        ? "right"
                                        : "left"
                                    }
                                    secondary={formatTime(message.created_at)}
                                  ></ListItemText>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <Grid container>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <Typography>
                            <span
                              style={{
                                float:
                                  message.sender_id === user.id
                                    ? "right"
                                    : "left",
                                backgroundColor: AppColor.defaultBgColor,
                                padding: 10,
                                borderRadius: 5,
                              }}
                            >
                              {message.message}
                            </span>
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <ListItemText
                            align={
                              message.sender_id === user.id ? "right" : "left"
                            }
                            secondary={formatTime(message.created_at)}
                          ></ListItemText>
                        </Grid>
                      </Grid>
                    )}
                    <div ref={bottomRef} />
                  </ListItem>
                ))}
                {conversation.conversationStatus === 0 &&
                  user.id !== conversation.receiver_id && (
                    <div>
                      <Typography style={{ fontSize: 14, textAlign: "center" }}>
                        Message request has been sent.
                      </Typography>
                      <Typography style={{ fontSize: 14, textAlign: "center" }}>
                        Recipient must accept the request to start the
                        conversation.
                      </Typography>
                    </div>
                  )}
                {conversation.conversationStatus === 0 &&
                  user.id === conversation.receiver_id && (
                    <div>
                      <Typography style={{ fontSize: 14, textAlign: "center" }}>
                        {conversation.sender_name} Wants to connect with you for{" "}
                        {conversation.requestOption}
                      </Typography>
                      <Typography style={{ fontSize: 14, textAlign: "center" }}>
                        Do you want to accept and start the conversation?
                      </Typography>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box style={{ margin: 30 }}>
                            <CustomPrimaryButton
                              text="Block"
                              handleClick={handleBlock}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box style={{ margin: 30 }}>
                            <CustomPrimaryButton
                              text="Accept"
                              handleClick={handleAccept}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                {conversation.conversationStatus === 2 &&
                  user.id === conversation.blockedUserId && (
                    <Typography style={{ fontSize: 14, textAlign: "center" }}>
                      You Are Blocked. You Can't Reply to this conversation
                    </Typography>
                  )}
                {conversation.conversationStatus === 3 && endedConversation && (
                  <Typography style={{ fontSize: 14, textAlign: "center" }}>
                    You have ended this conversation
                  </Typography>
                )}

                {conversation &&
                  conversation.conversationStatus === 2 &&
                  player?.id === conversation.blockedUserId && (
                    <Button
                      variant="contained"
                      className="custom-btn-primary"
                      onClick={handleUnblock}
                      style={{
                        textTransform: "none",
                        height: 54,
                        position: "absolute",
                        bottom: 0,
                        right: 0,
                      }}
                      disableElevation
                    >
                      Unblock
                    </Button>
                  )}
              </List>
              {conversation.conversationStatus === 1 && (
                <div>
                  <Divider />
                  <Grid container style={{ padding: "20px" }}>
                    <Grid item xs={11}>
                      <TextField
                        label="Type message here"
                        fullWidth
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={1} align="right">
                      <Fab
                        color="primary"
                        aria-label="add"
                        onClick={handleSendMessage}
                      >
                        <SendIcon />
                      </Fab>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
          </Grid>
        )}
        {(!conversation ||
          (conversation?.conversationStatus === 3 &&
            endedConversation === false)) &&
          showOptionBlock && (
            <Box>
              <Typography
                id="modal-title"
                variant="h4"
                component="h2"
                style={{ fontSize: 15 }}
              >
                Why do you want to contact{" "}
                <span style={{ fontWeight: "700" }}>
                  {contactPlayer?.name ?? player?.full_name ?? "N/A"} ?
                </span>
              </Typography>
              <Box style={{ marginTop: 30 }}>
                <Button
                  disableElevation
                  variant="contained"
                  className={
                    requestOption === "General Enquiry"
                      ? "custom-btn-secondary-selected"
                      : "custom-btn-secondary"
                  }
                  onClick={() => handleContactRequestOption("General Enquiry")}
                  style={{ textTransform: "none", height: 54 }}
                >
                  General Enquiry
                </Button>
              </Box>

              {player?.type === 2 ? (
                <Box style={{ marginTop: 30 }}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      requestOption === "Start a Conversation"
                        ? "custom-btn-secondary-selected"
                        : "custom-btn-secondary"
                    }
                    onClick={() =>
                      handleContactRequestOption("Start a Conversation")
                    }
                    style={{ textTransform: "none", height: 54 }}
                  >
                    Start a Conversation
                  </Button>
                </Box>
              ) : (
                <Box style={{ marginTop: 30 }}>
                  <Button
                    disableElevation
                    variant="contained"
                    className={
                      requestOption === "Playing Offer"
                        ? "custom-btn-secondary-selected"
                        : "custom-btn-secondary"
                    }
                    onClick={() => handleContactRequestOption("Playing Offer")}
                    style={{ textTransform: "none", height: 54 }}
                  >
                    Submit a Playing Offer
                  </Button>
                </Box>
              )}

              <Box style={{ marginTop: 30 }}>
                <Button
                  disableElevation
                  variant="contained"
                  className={
                    requestOption === "Endorsement Offer"
                      ? "custom-btn-secondary-selected"
                      : "custom-btn-secondary"
                  }
                  onClick={() =>
                    handleContactRequestOption("Endorsement Offer")
                  }
                  style={{ textTransform: "none", height: 54 }}
                >
                  Submit a Endorsement Offer
                </Button>
              </Box>

              <Box style={{ marginTop: 30, marginBottom: 20 }}>
                <CustomPrimaryButton text="Next" handleClick={handleNext} />
              </Box>
            </Box>
          )}
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className={classes.modal}>
          <Typography
            id="modal-title"
            variant="h4"
            component="h2"
            style={{ fontSize: 15, marginBottom: 20 }}
          >
            Report{" "}
            <span style={{ fontWeight: "700" }}>
              {contactPlayer?.name ?? player?.full_name ?? "N/A"}
            </span>
          </Typography>
          <Notify />
          <FormControl
            style={{
              width: "100%",
              marginBottom: 20,
              border: "1px solid #7CD1DC",
              borderRadius: "4px",
              backgroundColor: AppColor.fieldBackground,
            }}
          >
            <InputLabel id="multiple-select-label" style={{ marginLeft: 6 }}>
              Select Reason
            </InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              disableUnderline
              value={reasonId}
              onChange={(e) => setReasonId(e.target.value)}
            >
              {reasons.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  <Typography style={{ marginLeft: 6, marginBottom: 8 }}>
                    {option.reason}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextareaAutosize
            minRows={5}
            variant="outlined"
            placeholder="Message"
            value={message}
            className={classes.textarea}
            style={{ marginBottom: 20 }}
            onChange={(e) => setMessage(e.target.value)}
          />

          <FormControlLabel
            control={<Checkbox onClick={handleCheck} />}
            label="Block User"
            style={{ marginBottom: 20 }}
          />

          <CustomPrimaryButton text="Submit" handleClick={handleSubmit} />
        </Box>
      </Modal>
    </Page>
  );
}
