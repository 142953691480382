import React from "react";
import { Navigate } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import Login from "../views/Login";
import Register from "../views/Register";
import Home from "../views/Home";
import IAM from "../views/IAM";
import ForgotPassword from "../views/ForgotPassword";
import Page404 from "../views/Pages/404";
import GetStarted from "../views/GetStarted";
import PlayerList from "../views/Home/PlayerList";
import Profile from "../views/Home/Profile";
import EditProfile from "../views/Home/Profile/edit";
import RegisterStep1 from "../views/Register/Step1";
import RegisterStep2 from "../views/Register/Step2";
import RegisterStep3 from "../views/Register/Step3";
import MemberList from "../views/Home/MemberList";
import ProfileStep1 from "../views/Home/Profile/Step1";
import ProfileStep2 from "../views/Home/Profile/Step2";
import ProfileStep3 from "../views/Home/Profile/Step3";
import MemberDetail from "../views/Home/MemberDetail";
import CoachDetail from "../views/Home/CoachDetail";
import AgentDetail from "../views/Home/AgentDetail";
import TeamDetail from "../views/Home/TeamDetail";
import PlayerHighlights from "../views/Home/PlayerHighlights";
import Mobile from "../views/Mobile";
import Messages from "../views/Messages";
import Chat from "../views/Messages/Chat";
import RegisterTeamProfile from "../views/Register/team";
import RegisterCoachProfile from "../views/Register/coach";
import RegisterCoachSport from "../views/Register/sport";
import RegisterAgentProfile from "../views/Register/agent";
import AgentProfile from "../views/Home/Profile/agent";
import FavouriteList from "../views/Home/Favourite";
import AboutUs from "../views/about";
import PrivacyPolicy from "../views/privacy";
import Terms from "../views/terms";
import ContactUs from "../views/contact";
import Settings from "../views/settings";
import ChangePassword from "../views/Home/Profile/changepassword";
import AgentPlayers from "../views/Home/AgentDetail/player";
import Enquiry from "../views/Inquiry";
import MessageRequests from "../views/Messages/requests";
import Notifications from "../views/Home/Notifications";
import PlayerRegisterStep1 from "../views/Home/AgentDetail/Register/Step1";
import PlayerRegisterStep2 from "../views/Home/AgentDetail/Register/Step2";
import SchoolPlayerRegisterStep1 from "../views/Home/SchoolDetail/Register/Step1";
import SchoolPlayerRegisterStep2 from "../views/Home/SchoolDetail/Register/Step2";
import RegisterSchoolSport from "../views/Register/school-sport";
import RegisterSchoolProfile from "../views/Register/school";
import SchoolPlayers from "../views/Home/SchoolDetail/player";
import SchoolDetail from "../views/Home/SchoolDetail";
import SchoolProfile from "../views/Home/Profile/school";

function RenderRoutes() {
  let isAuthorize = Boolean(window.state().authorize.id);

  return (
    <Routes>
      <Route
        path="/"
        element={isAuthorize ? <Navigate to="home" /> : <GetStarted />}
      />
      <Route path="/iam" element={<IAM />} />
      <Route
        path="/login"
        element={!isAuthorize ? <Login /> : <Navigate to="/home" />}
      />
      <Route
        path="/register"
        element={!isAuthorize ? <Register /> : <Navigate to="/home" />}
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/404" element={<Page404 />} />
      <Route path="/*" element={<Navigate to="/404" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/mobile" element={<Mobile />} />
      <Route
        path="/register/step-1"
        element={isAuthorize ? <RegisterStep1 /> : <Navigate to="/login" />}
      />
      <Route
        path="/register/step-2"
        element={isAuthorize ? <RegisterStep2 /> : <Navigate to="/login" />}
      />
      <Route
        path="/register/step-3"
        element={isAuthorize ? <RegisterStep3 /> : <Navigate to="/login" />}
      />
      <Route
        path="/player/register/step-1"
        element={
          isAuthorize ? <PlayerRegisterStep1 /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/player/register/step-2"
        element={
          isAuthorize ? <PlayerRegisterStep2 /> : <Navigate to="/login" />
        }
      />
      <Route
        path="school/player/register/step-1"
        element={
          isAuthorize ? <SchoolPlayerRegisterStep1 /> : <Navigate to="/login" />
        }
      />
      <Route
        path="school/player/register/step-2"
        element={
          isAuthorize ? <SchoolPlayerRegisterStep2 /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/register/team"
        element={
          isAuthorize ? <RegisterTeamProfile /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/register/sport"
        element={
          isAuthorize ? <RegisterCoachSport /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/register/school/sport"
        element={
          isAuthorize ? <RegisterSchoolSport /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/register/coach"
        element={
          isAuthorize ? <RegisterCoachProfile /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/register/agent"
        element={
          isAuthorize ? <RegisterAgentProfile /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/register/school"
        element={
          isAuthorize ? <RegisterSchoolProfile /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/members/:category"
        element={isAuthorize ? <MemberList /> : <Navigate to="/login" />}
      />
      <Route
        path="/players/:category"
        element={isAuthorize ? <PlayerList /> : <Navigate to="/login" />}
      />
      <Route
        path="/member/:id"
        element={isAuthorize ? <MemberDetail /> : <Navigate to="/login" />}
      />
      <Route
        path="/player/highlights/:id"
        element={isAuthorize ? <PlayerHighlights /> : <Navigate to="/login" />}
      />
      <Route
        path="/agent/:id"
        element={isAuthorize ? <AgentDetail /> : <Navigate to="/login" />}
      />
      <Route
        path="/agent/:id/players"
        element={isAuthorize ? <AgentPlayers /> : <Navigate to="/login" />}
      />
      <Route
        path="/school/:id/players"
        element={isAuthorize ? <SchoolPlayers /> : <Navigate to="/login" />}
      />
      <Route
        path="/coach/:id"
        element={isAuthorize ? <CoachDetail /> : <Navigate to="/login" />}
      />
      <Route
        path="/team/:id"
        element={isAuthorize ? <TeamDetail /> : <Navigate to="/login" />}
      />
      <Route
        path="/school/:id"
        element={isAuthorize ? <SchoolDetail /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile"
        element={isAuthorize ? <Profile /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/edit"
        element={isAuthorize ? <EditProfile /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/step-1"
        element={isAuthorize ? <ProfileStep1 /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/step-2"
        element={isAuthorize ? <ProfileStep2 /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/step-3"
        element={isAuthorize ? <ProfileStep3 /> : <Navigate to="/login" />}
      />

      <Route
        path="/profile/agent"
        element={isAuthorize ? <AgentProfile /> : <Navigate to="/login" />}
      />

      <Route
        path="/profile/school"
        element={isAuthorize ? <SchoolProfile /> : <Navigate to="/login" />}
      />
      <Route
        path="/messages"
        element={isAuthorize ? <Messages /> : <Navigate to="/login" />}
      />
      <Route
        path="/messages/requests"
        element={isAuthorize ? <MessageRequests /> : <Navigate to="/login" />}
      />
      <Route
        path="/messages/:id"
        element={isAuthorize ? <Chat /> : <Navigate to="/login" />}
      />
      <Route
        path="/favourites"
        element={isAuthorize ? <FavouriteList /> : <Navigate to="/login" />}
      />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/terms-conditions" element={<Terms />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route
        path="/settings"
        element={isAuthorize ? <Settings /> : <Navigate to="/login" />}
      />
      <Route
        path="/profile/change-password"
        element={isAuthorize ? <ChangePassword /> : <Navigate to="/login" />}
      />
      <Route
        path="/enquiry"
        element={isAuthorize ? <Enquiry /> : <Navigate to="/login" />}
      />
      <Route
        path="/notifications"
        element={isAuthorize ? <Notifications /> : <Navigate to="/login" />}
      />
    </Routes>
  );
}

export default RenderRoutes;
