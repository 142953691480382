import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import { makeStyles } from "@material-ui/styles";
import "./index.css";
import Navbar from "../../layouts/Navigation";
import ImageBox from "../../components/ImageBox";
import {
  Container,
  Typography,
  Modal,
  Box,
  Button,
  Grid,
  Paper,
} from "@material-ui/core";
import SimpleBackdrop from "../../components/Common/SimpleBackdrop";
import { CATEGORIES } from "../../constants/ApiEndPoint";
import { fetchRecord } from "../../services/Axios";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AUTH_ROUTES, HOME_ROUTES } from "../../constants/site-routes";
import AppColor from "../../utils/color";
import CustomPrimaryButton from "../../components/Common/CustomPrimaryButton";

const modalStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  maxWidth: 300,
  bgcolor: "white",
  boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.2)",
  borderRadius: 8,
  textAlign: "center",
  padding: "16px",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  container: {
    marginBottom: "10px",
    marginTop: "10px",
    width: "45%",
  },
  buttonStyles: {
    marginTop: "16px",
    width: "45%",
    margin: "5px",
    textTransform: "none",
    backgroundColor: "#e4f5f8",
  },
  profileNotComplete: {
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    padding: 10,
    borderRadius: 5,
    marginTop: 15,
    marginBottom: 20,
  },
  profileNotCompleteHeader: {
    fontFamily: "Regular",
    fontSize: 14,
    color: AppColor.newTextColor,
  },
  profileStepsCompleted: {
    fontFamily: "Bold",
    fontSize: 12,
    fontStyle: "italic",
    color: AppColor.newTextColor,
    marginLeft: 8,
    marginTop: 10,
  },
  profileNotCompleteSubHeader: {
    fontSize: 14,
    color: AppColor.newTextColor,
    fontFamily: "Regular",
    lineHeight: 1.5,
  },
  completeProfile: {
    color: AppColor.newDarkBlue,
    fontSize: 10,
    textDecoration: "none",
  },
  guestImage: {
    position: "relative",
    maxWidth: "100%",
    marginTop: "20px",
  },
  searchBox: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #ccc",
    borderRadius: 4,
    padding: 5,
    backgroundColor: "#91919159",
    marginTop: 10,
    "& input": {
      flex: 1,
      border: "none",
      outline: "none",
      padding: 5,
      backgroundColor: "#91919100",
      fontSize: 16,
      color: "#000000",
    },
    "& input::placeholder": {
      color: "#000000",
    },
    "& button": {
      backgroundColor: "transparent",
      border: "none",
      cursor: "pointer",
      padding: 5,
    },
  },
}));

const Home = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const user = useSelector((state) => state.authorize);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  // const [query, setQuery] = useState("");
  const [profileNotCompleteFlag, setProfileNotCompleteFlag] = useState(false);

  const fetchSports = async () => {
    setLoading(true);
    const response = await fetchRecord(CATEGORIES.LIST);
    setLoading(false);
    if (response && response.data && response.status === 200) {
      setCategories(response.data);
    }
  };

  const showLoginModal = () => {
    setOpen(true);
  };

  const showProfileNotCompleteModel = () => {
    setProfileNotCompleteFlag(true);
  };

  const showCommingSoon = () => {};

  const handleClose = () => {
    setOpen(false);
  };

  const handleNo = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchSports();
  }, []);

  const handleSignInClick = () => {
    navigate(AUTH_ROUTES.LOGIN);
  };

  const handleSignUpClick = () => {
    navigate(AUTH_ROUTES.IAM);
  };

  // const handelFilter = async () => {
  //   setLoading(true);
  //   const response = await fetchRecord(CATEGORIES.LIST, "", "", "q=" + query);
  //   setLoading(false);
  //   if (response && response.data && response.status === 200) {
  //     setCategories(response.data);
  //   }
  // };

  const handleEnquiry = () => {
    navigate(HOME_ROUTES.ENQUIRY);
  };

  const handleCompleteProfileErrorAler = () => {
    setProfileNotCompleteFlag(false);
  };

  return (
    <>
      <Page className={classes.root} title="Home" backgroundColor="#ffffff">
        {loading && <SimpleBackdrop />}
        <Navbar />
        <Container className={classes.container}>
          <Grid className="home-block" container spacing={0}>
            <Paper elevation={0} style={{ textAlign: "center" }}>
              <img src="/icons/talent_logo_blue.png" width={"50%"} alt=""/>
            </Paper>
          </Grid>
          <Grid container spacing={0} style={{ marginTop: 30 }}>
            <Grid item xs={6}>
              <Paper elevation={0}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: 28,
                    fontFamily: "Noventa812, sans-serif",
                  }}
                >
                  HOME
                </Typography>
              </Paper>
            </Grid>
            {/* <Grid item xs={6}>
              <Paper
                elevation={0}
                style={{ textAlign: "right", marginTop: 10 }}
              >
                <Link to="/settings" style={{ textDecoration: "none" }}>
                  <img src="/icons/img_setting_unselect.png" width={30} alt=""/>
                </Link>
              </Paper>
            </Grid> */}
          </Grid>
          {categories && categories.length > 0 && (
            <Typography style={{ fontFamily: "Bold", marginTop: 10 }}>
              Sports
            </Typography>
          )}

          {user && user.is_profile_completed === "0" && (
            <Box className={classes.profileNotComplete}>
              <Grid container spacing={0} style={{ paddingTop: 15 }}>
                <Grid item xs={1}>
                  <Paper elevation={0}>
                    <img
                      src="/icons/manage_accounts.png"
                      style={{ width: 48 }}
                      alt=""
                    />
                  </Paper>
                </Grid>
                <Grid item xs={11}>
                  <Paper
                    elevation={0}
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  >
                    <Typography style={{ fontFamily: "Medium", fontSize: 14 }}>
                      Please complete your player's profile to appear in the
                      player's list. Then others can find you too
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              {user.type === 2 && (
                <Grid container spacing={2} style={{ marginTop: 5 }}>
                  <Grid item xs={5}>
                    <Paper
                      elevation={0}
                      style={{
                        height: 15,
                        backgroundColor:
                          user.player_register_step > 1
                            ? "#7CD1DC"
                            : "rgb(124 209 220 / 32%)",
                      }}
                    ></Paper>
                  </Grid>
                  <Grid item xs={5}>
                    <Paper
                      elevation={0}
                      style={{
                        height: 15,
                        backgroundColor:
                          user.player_register_step > 1
                            ? "#7CD1DC"
                            : "rgb(124 209 220 / 32%)",
                      }}
                    ></Paper>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      style={{
                        marginTop: "-5%",
                        fontFamily: "SemiBold",
                        fontSize: 14,
                      }}
                    >
                      {parseInt((user.player_register_step / 3) * 100)}%{" "}
                    </Typography>
                  </Grid>
                </Grid>
              )}

              {user.type === 2 && (
                <Grid container spacing={2}>
                  <Typography className={classes.profileStepsCompleted}>
                    You have {2 - user.player_register_step} more steps to go
                    ...
                  </Typography>
                </Grid>
              )}

              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <Paper
                    elevation={0}
                    style={{
                      textAlign: "center",
                      border: `1px ${AppColor.newDarkBlue} solid`,
                      borderRadius: 6,
                      marginTop: 20,
                    }}
                  >
                    <Link to="/profile" className={classes.completeProfile}>
                      <Typography
                        style={{
                          fontFamily: "SemiBold",
                          fontSize: 16,
                          padding: 10,
                        }}
                      >
                        Complete Profile
                      </Typography>
                    </Link>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}

          {!user && (
            <Box className={classes.profileNotComplete}>
              <Grid container spacing={0} style={{ paddingTop: 15 }}>
                <Grid item xs={1}>
                  <Paper elevation={0}>
                    <img src="/icons/report.png" style={{ width: 48 }} alt=""/>
                  </Paper>
                </Grid>
                <Grid item xs={11}>
                  <Paper
                    elevation={0}
                    style={{ paddingLeft: 8, paddingRight: 8 }}
                  >
                    <Typography style={{ fontFamily: "Medium", fontSize: 14 }}>
                      Thank you for visiting Talent Lockr as a guest, please
                      signup up to continue to use further feature.
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <Paper
                    elevation={0}
                    style={{
                      textAlign: "center",
                      border: `1px ${AppColor.newDarkBlue} solid`,
                      borderRadius: 6,
                      marginTop: 20,
                    }}
                  >
                    <Button
                      onClick={handleSignInClick}
                      style={{
                        color: AppColor.newDarkBlue,
                        fontFamily: "SemiBold",
                        fontSize: 16,
                        padding: 6,
                      }}
                    >
                      Sign In
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ paddingBottom: 20 }}>
                  <Paper
                    elevation={0}
                    style={{
                      textAlign: "center",
                      border: `1px ${AppColor.newLightBlue} solid`,
                      borderRadius: 6,
                      background: AppColor.newLightBlue,
                    }}
                  >
                    <Button
                      onClick={handleSignUpClick}
                      style={{
                        color: "white",
                        fontFamily: "SemiBold",
                        fontSize: 16,
                        padding: 6,
                      }}
                    >
                      Sing Up
                    </Button>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}

          {categories &&
            categories.map((category, index) => {
              return (
                category.is_comming_soon === 0 && (
                  <ImageBox
                    key={index}
                    category={category}
                    user={user}
                    showLoginModal={showLoginModal}
                    showProfileNotCompleteModel={showProfileNotCompleteModel}
                  />
                )
              );
            })}

          {categories && categories.length > 0 && (
            <Typography style={{ fontFamily: "SemiBold", marginTop: 20 }}>
              Comming Soon
            </Typography>
          )}
          {categories &&
            categories.map((category, index) => {
              return (
                category.is_comming_soon === 1 && (
                  <ImageBox
                    key={index}
                    category={category}
                    user={user}
                    showLoginModal={showCommingSoon}
                  />
                )
              );
            })}

          {user && user.is_profile_completed === "1" && (
            <Container
              className={classes.container}
              style={{ marginTop: 20, width: "100%" }}
            >
              <CustomPrimaryButton
                text="Enquiry Now"
                handleClick={handleEnquiry}
              />
            </Container>
          )}
        </Container>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={modalStyles}>
            <Typography
              id="modal-title"
              variant="h4"
              component="h2"
              style={{ fontSize: 15, fontWeight: "700" }}
            >
              To continue using TalentLockr app you must be signed up
            </Typography>
            <Button
              variant="contained"
              onClick={handleNo}
              className={classes.buttonStyles}
              disableElevation
              style={{ color: AppColor.textColor }}
            >
              Not Now
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSignUpClick}
              className={classes.buttonStyles}
              disableElevation
            >
              Sign Up
            </Button>
          </Box>
        </Modal>

        <Modal
          open={profileNotCompleteFlag}
          onClose={handleCompleteProfileErrorAler}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box sx={modalStyles}>
            <Grid container spacing={1}>
              <Paper
                elevation={0}
                style={{ textAlign: "center", width: "100%" }}
              >
                <img src="/icons/error_alert.png" width={40} alt=""/>
              </Paper>
            </Grid>
            <Grid container spacing={1} style={{ marginTop: 25 }}>
              <Typography
                id="modal-title"
                variant="h4"
                component="h2"
                style={{
                  fontSize: 15,
                  fontFamily: "SemiBold",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                {user.type === 2 && "Complete Player's Profile"}
                {user.type !== 2 && "Complete Profile"}
              </Typography>
              <Typography
                style={{
                  fontFamily: "Medium",
                  fontSize: 13,
                  color: "rgb(87 88 91 / 80%)",
                  marginTop: 15,
                }}
              >
                {user.type === 2 &&
                  "Your Player's profile is not completed please complete your player's profile to appear in the player's list"}
                {user.type !== 2 &&
                  "Your profile is not completed please complete your player's profile to appear in the player's list"}
              </Typography>
              <Link
                to="/profile"
                className={classes.buttonStyles}
                style={{
                  color: AppColor.textColor,
                  textDecoration: "none",
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <Typography style={{ fontFamily: "Medium", padding: 8 }}>
                  OK
                </Typography>
              </Link>
            </Grid>
          </Box>
        </Modal>
      </Page>
    </>
  );
};

export default Home;
